import * as React from 'react';
import {
    Avatar,
    Badge,
    Box,
    IconButton,
    Toolbar,
    Tooltip,
    Typography,
    AvatarGroup,
} from "@mui/material";
import { DeleteOutlineOutlined, ModeEditOutlined, Group, Groups } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";

// project import
import { SkeletonContactsAppBar } from "../Skeletons";
import AddContactsMenu from "./AddContactsMenu";
import store from "../../store/store";
import {
    openAlertDeleteLabelDialog,
    openRenameLabelDialog, openShareLabelsDialog,
    openWarningShareAllContactsDialog,
} from "../../store/reducers/dialogSlice";
import {
    setDeleteCurrentGroup,
    setGroupsToShare,
    setLabelToRename
} from "../../store/reducers/groupsSlice";
import { checkRole } from "../../services/checkRole";
import * as RolesConstants from "../../constants/roles";
import { getSharedUsers } from "../../services/getSharedUsers";
import { ShareLabelIcon } from "./ShareLabelIcon";
import { UserAvatar } from "../UserAvatar";
import { TOOLBAR_HEIGHT } from "../../constants/size";

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export default function ContactsAppBar() {
    const statusFirstLoad = useSelector((state) => state.contacts.statusFirstLoad);
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    const user = useSelector((state) => state.user.user);
    const contacts = useSelector((state) => state.contacts.contacts);
    const shareableUsers = useSelector((state) => state.user.shareableUsers);
    const groups = useSelector((state) => state.groups.groups);
    let usersSharedWith = currentGroup?.share?.length ? [...currentGroup.share.filter(item => item.type === 1), ...currentGroup.domains_groups_share.map(item => ({
        ...item,
        isGroup: true
    }))] : [];
    const shareLabelStatus = useSelector((state) => state.groups.shareLabelStatus);
    const loading = shareLabelStatus === "loading";
    const sharingUsers = useSelector((state) => state.groups.sharingUsers);
    const sharingGroups = useSelector((state) => state.groups.sharingGroups);
    const isViewPermission = currentGroup?.role === 'VIEW';
    if (sharingUsers.length && sharingGroups.find(item => item === currentGroup.resource)) {
        usersSharedWith = [...usersSharedWith, ...sharingUsers.map(email => ({ email }))];
    }
    if (statusFirstLoad === 'loading') return (
        <SkeletonContactsAppBar DrawerHeader={DrawerHeader} />
    )
    const labelName = currentGroup ? currentGroup.resource === 'contactGroups/myContacts' ? 'All Contacts' : currentGroup.name : '';
    const handleClickOpenRenameLabelDialog = () => {
        store.dispatch(setLabelToRename(
            {
                resource: currentGroup.resource,
                name: groups.find(group => group.resource === currentGroup.resource).name
            }
        ));
        store.dispatch(openRenameLabelDialog(true));
    };
    const handleClickOpenAlertDeleteLabelDialog = () => {
        store.dispatch(setDeleteCurrentGroup(true));
        store.dispatch(openAlertDeleteLabelDialog(true));
    };
    const handleShareLabel = () => {
        store.dispatch(setGroupsToShare({ groups: [{ group: currentGroup.resource }] }));
        if (currentGroup.resource === 'contactGroups/myContacts') {
            const myContacts = groups.find(group => group.resource === 'contactGroups/myContacts');
            if (myContacts && myContacts.share && myContacts.share.length) {
                store.dispatch(openShareLabelsDialog(true));
                return;
            }
            store.dispatch(openWarningShareAllContactsDialog(true));
        } else {
            store.dispatch(openShareLabelsDialog(true));
        }
    };
    let sharedUsers = getSharedUsers(currentGroup, user, contacts, shareableUsers);
    sharedUsers = sharedUsers.filter(sharedUser => sharedUser.email !== user.email);
    const ShareLabelButtonText = () => {
        return (
            <Typography variant={'button'}
                sx={{
                    textTransform: "none",
                    color: loading ? 'rgba(0, 0, 0, 0.32)' : '#FFF',
                    letterSpacing: 0,
                }}
            >
                {isViewPermission ? 'Access overview' : 'Manage access'}
            </Typography>
        )
    }

    return (
        <Toolbar sx={{ height: `${TOOLBAR_HEIGHT}px` }}>
            <Typography
                variant={'subtitle2'}
                noWrap
                sx={{
                    ml: -1.2,
                    width: 'auto',
                    maxWidth: '200px',
                    paddingRight: '8px',
                }}
            >
                {labelName}
            </Typography>
            {checkRole(RolesConstants.ROLE_EDIT, currentGroup) && <AddContactsMenu buttonType={'icon'} />}
            {checkRole(RolesConstants.ROLE_EDIT, currentGroup) && <Tooltip title='Rename label' placement="bottom">
                <span>
                    <IconButton
                        onClick={handleClickOpenRenameLabelDialog}
                        disabled={currentGroup?.resource === 'contactGroups/myContacts'}
                    >
                        <ModeEditOutlined />
                    </IconButton>
                </span>
            </Tooltip>}
            {checkRole(RolesConstants.ROLE_DELETE, currentGroup) && <Tooltip
                title='Your contacts will not be deleted. They will be available in All Contacts.  If you share this label with users, the label and its contacts will be deleted on their end.'
                placement="bottom">
                <span>
                    <IconButton
                        onClick={handleClickOpenAlertDeleteLabelDialog}
                        disabled={currentGroup?.resource === 'contactGroups/myContacts'}
                    >
                        <DeleteOutlineOutlined />
                    </IconButton>
                </span>
            </Tooltip>}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: 'flex' }}>
                <SharedUsersAvatar sharedUsers={sharedUsers} />
                <Box ml={'20px'}>
                    <LoadingButton
                        variant="contained"
                        onClick={handleShareLabel}
                        loading={loading}
                        loadingPosition="start"
                        sx={{
                            background: "rgba(59, 125, 237, 1)",
                            "&.Mui-disabled": {
                                background: "rgba(0, 0, 0, 0.08)"
                            },
                            marginRight: '-4px',
                        }}
                        startIcon={
                            <ShareLabelIcon isShareDisabled={false} />
                        }
                    >
                        <ShareLabelButtonText />
                    </LoadingButton>
                </Box>
            </Box>
        </Toolbar>
    )
}

const SharedUsersAvatar = ({ sharedUsers }) => {
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    return (
        <AvatarGroup max={5}
            key={'SharedUsersAvatar'}
            spacing={'medium'}
            renderSurplus={(surplus) => <span>+{surplus}</span>}
            total={sharedUsers.length < 100 ? sharedUsers.length : 99 + 4}
            sx={{
                '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    color: 'rgba(0, 0, 0, 0.87)',
                    fontSize: 13,
                    fontFamily: `"Open Sans"`,
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '16px',
                },
            }}
        >
            {
                sharedUsers.map((sharedUser => {
                    let groupSyncStatus = '';
                    if (sharedUser.shareType === 'GROUP') {
                        const usersInDomainGroup = currentGroup.share.filter(item => item.domain_group_email === sharedUser.email);
                        const usersInDomainGroupCount = usersInDomainGroup.length || sharedUser.count || 0;
                        const usersWithStatusOK = usersInDomainGroup.filter(user => user.sync_status === 'OK');
                        groupSyncStatus = usersInDomainGroupCount === usersWithStatusOK.length ? 'OK' : `ON_QUEUE`;
                    }
                    return (
                        <Tooltip
                            placement="top"
                            title={sharedUser.name}
                            key={sharedUser.email}
                        >
                            <Badge
                                key={sharedUser.email}
                                color={getSyncStatus(sharedUser.shareType !== 'GROUP' ? sharedUser.sync_status : groupSyncStatus)}
                                variant={'dot'}
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                            >
                                {sharedUser.shareType !== 'GROUP' ?
                                    <UserAvatar alt={sharedUser.name}
                                        sx={{ width: 32, height: 32 }}
                                        src={sharedUser.photo} /> :
                                    <Avatar alt={sharedUser.name} sx={{ background: '#E8F0FE', width: 32, height: 32 }}>
                                        <Groups sx={{ color: '#3B7DED' }} />
                                    </Avatar>}
                            </Badge>
                        </Tooltip>
                    )
                }))
            }
        </AvatarGroup>
    )
}

const getSyncStatus = (status) => {
    let result = '';
    switch (status) {
        case 'ON_QUEUE':
            result = 'warning';
            break;
        case 'OK':
            result = 'success';
            break;
        case 'WAITING_LABEL':
            result = 'error';
            break;
        case 'WAITING_CONTACTS':
            result = 'warning';
            break;
        default:
            result = 'grey';
    }
    return result;
}
