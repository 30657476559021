import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";

export const StyledDataGrid = styled(DataGrid)(({ theme }) => (
    {
        border: "none !important",
        color:
            theme.palette.mode === "light"
                ? "rgba(0,0,0,.85)"
                : "rgba(255,255,255,0.85)",
        WebkitFontSmoothing: "auto",
        letterSpacing: "normal",
        borderRadius: 0,
        "& .MuiDataGrid-columnsContainer": {
            backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d"
        },
        "& .MuiDataGrid-iconSeparator": {
            display: "none"
        },
        "& .MuiDataGrid-columnHeader": {
            color: "rgba(0, 0, 0, 0.87)",
            fontSize: 14,
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "20px",
            letterSpacing: "0.17px",
            border: "none !important"
        },
        "& .MuiDataGrid-cell": {
            color: "rgba(0, 0, 0, 0.87)",
            fontSize: 14,
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "20px",
            letterSpacing: "0.2px",
            border: "none !important"
        },
        "& .MuiPaginationItem-root": {
            borderRadius: 0
        },
        "& .MuiDataGrid-columnSeparator": {
            display: "none"
        },
        "& .MuiDataGrid-row": {
            border: "none !important",
            overflow: "hidden",
        },
        "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:active": {
            outline: "none !important",
            border: "none !important",
        },
        "& .MuiDataGrid-cell *:focus, & .MuiDataGrid-cell *:active": {
            outline: "none !important",
            border: "none !important",
            boxShadow: "none !important",
        },
        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:hover": {
            outline: "none !important",
            border: "none !important"
        },
        "& .MuiDataGrid-virtualScroller": {
            border: "none !important"
        },
        "& .MuiDataGrid-root": {
            boxShadow: "none"
        },
        "& .MuiDataGrid-footerContainer": {
            borderTop: "none !important"
        },
        "& .MuiBadge-badge": {
            display: "none"
        },
        "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none !important"
        },
        '& .actionIcons': {
            opacity: 0,
            transition: 'opacity 0.3s',
            display: 'flex',
        },
        '& .MuiDataGrid-row:hover .actionIcons': {
            opacity: 1,
        },
        '& .MuiDataGrid-row:hover': {
            backgroundColor: '#EEF3FB',
        },
        '& .Mui-selected': {
            backgroundColor: '#D1E1FA !important',
        },
        '& .selected-row': {
            backgroundColor: '#D1E1FA !important',
        },
        "& .MuiDataGrid-virtualScroller .MuiDataGrid-row": {
            borderRadius: '0px',
            // margin: '2px 0',
        },
        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
            outline: "none"
        },
        "& .MuiDataGrid-columnHeaderIcon": {
            outline: "none"
        },
        "& .MuiIconButton-root": {
            outline: "none"
        },
    }
));

