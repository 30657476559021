import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import moment from 'moment';
import {
    AddCircleOutlineOutlined,
    CakeOutlined,
    CalendarTodayOutlined,
    CloseOutlined,
    EmailOutlined,
    FormatAlignLeftOutlined,
    HomeOutlined,
    PersonOutlineOutlined, PhoneOutlined,
    WorkOutlineOutlined,
} from "@mui/icons-material";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    FormHelperText,
} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect } from "react";
import dayjs from 'dayjs';
import countries from '../../countries.json';

// project import
import { openEditContactDialog } from "../../store/reducers/dialogSlice";
import { updateContact } from "../../store/reducers/contactsSlice";

const labelOptions = [
    {
        label: "Personal",
        value: "Personal",
    },
    {
        label: "Work",
        value: "Work",
    },
    {
        label: "Other",
        value: "Other",
    },
];

const defaultValues = {
    surname: '',
    name: '',
    organization: '',
    jobTitle: '',
    emails: [{ email: '', label: '' }],
    phones: [{ phone: '', label: '' }],
    country: '',
    region: '',
    city: '',
    street: '',
    street2: '',
    streetPostcode: '',
    // dateOfBirth: null,
    day: '',
    month: '',
    year: '',
    description: '',
}


const getLabelName = (item) => {
    const type = item.type[0].toUpperCase() + item.type.slice(1);
    const res = labelOptions.find(label => {
        return label.label === type
    })
    if (!res) {
        labelOptions.push({ label: type, value: type })
    }
    return res ? res.label : type
}

export default function EditContactDialog() {
    const open = useSelector((state) => state.dialogsOpen.editContact.value);
    const dispatch = useDispatch();
    const statusAllData = useSelector((state) => state.data.status);
    const statusContacts = useSelector((state) => state.contacts.status);
    const fullContact = useSelector((state) => state.contacts.fullContact);
    useEffect(() => {
        if (fullContact) {
            reset({
                surname: fullContact?.name?.familyName,
                name: fullContact?.name?.givenName,
                organization: fullContact && fullContact.organizations && fullContact.organizations[0].name,
                jobTitle: fullContact && fullContact.organizations && fullContact.organizations[0].title,
                emails: fullContact && fullContact.emailAddresses && fullContact.emailAddresses.map(email => ({
                    email: email.value,
                    label: email.type ? getLabelName(email) : '',
                })),
                phones: fullContact.phoneNumbers && fullContact.phoneNumbers.length && fullContact.phoneNumbers.map(phone => ({
                    phone: phone.value,
                    label: phone.type ? getLabelName(phone) : '',
                })),
                country: fullContact && fullContact.addresses && fullContact.addresses[0].countryCode,
                region: fullContact && fullContact.addresses && fullContact.addresses[0].region,
                city: fullContact && fullContact.addresses && fullContact.addresses[0].city,
                street: fullContact && fullContact.addresses && fullContact.addresses[0].streetAddress,
                street2: fullContact && fullContact.addresses && fullContact.addresses[0].extendedAddress,
                streetPostcode: fullContact && fullContact.addresses && fullContact.addresses[0].postalCode,
                // dateOfBirth: fullContact?.birthday && fullContact?.birthday.date && dayjs(new Date(fullContact?.birthday?.date.year, fullContact?.birthday?.date.month - 1, fullContact?.birthday?.date.day)),
                day: fullContact?.birthday?.date.day,
                month: fullContact?.birthday?.date.month,
                year: fullContact?.birthday?.date.year || '',
                description: fullContact?.biographie?.value,
            });
        }
    }, [fullContact]);
    const { handleSubmit, reset, control, formState: { errors }, setValue, watch, trigger } = useForm({
        defaultValues,
        mode: 'onChange'
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "emails"
    });
    const { fields: phonesFields, append: appendPhone, remove: removePhone } = useFieldArray({
        control,
        name: "phones"
    });
    const formValues = watch();
    const selectedDay = watch('day');
    const selectedMonth = watch('month');
    const selectedYear = watch('year');
    const months = moment.months();
    const hasAnyValue = (values) => {
        const stringFields = [
            'surname', 'name', 'organization', 'jobTitle', 'country',
            'region', 'city', 'street', 'street2', 'streetPostcode', 'description'
        ];
        if (stringFields.some(field => values[field]?.trim())) {
            return true;
        }
        if (values.emails?.some(email => email.email?.trim())) {
            return true;
        }
        if (values.phones?.some(phone => phone.phone?.trim())) {
            return true;
        }
        if (values.day && values.month) {
            return true;
        }
        return false;
    };
    if (!open) return null;
    const loading = statusAllData === 'loading' || statusContacts === 'loading';
    const onSubmit = async data => {
        const date = new Date(data.dateOfBirth);
        data.birthday = {
            day: Number(data.day) || null,
            month: Number(data.month) || null,
            year: Number(data.year) || null,
        }
        data.etag = fullContact?.etag;
        data.resourceName = fullContact.resourceName;
        await dispatch(updateContact(data));
        dispatch(openEditContactDialog(false));
    }
    const handleClose = () => {
        reset(defaultValues);
        dispatch(openEditContactDialog(false));
    };
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                id='AddContactDialog'
                PaperProps={{
                    sx: {
                        width: 720,
                        maxWidth: 720,
                        minWidth: 720,
                        backgroundColor: 'rgba(59, 125, 237, 1)'
                    }
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle sx={{ backgroundColor: 'rgba(59, 125, 237, 1)' }}>
                        <Grid container sx={{ alignItems: 'center' }}>
                            <Grid item>
                                <Typography
                                    variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Edit contact</Typography>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 12,
                            top: 12,
                            color: 'rgba(255, 255, 255, 0.54)',
                            '&:hover': {
                                color: 'rgba(255, 255, 255, 0.87)',
                                backgroundColor: 'rgba(0, 0, 0, 0.16)'
                            },
                        }}
                    >
                        <CloseOutlined />
                    </IconButton>
                    <DialogContent dividers sx={{ border: 0, backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container
                                alignItems="flex-end"
                            >
                                <Grid item xs={0.75}>
                                    <PersonOutlineOutlined sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                                </Grid>
                                <Grid item xs={5.1}>
                                    <FormInputText
                                        name={"name"}
                                        label={"Name"}
                                        control={control}
                                        autoFocus
                                        rules={{
                                            required: false,
                                            maxLength: {
                                                value: 255,
                                                message: "Max 255 symbols"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5.1}>
                                    <FormInputText
                                        sx={{ marginLeft: 3 }}
                                        name={"surname"}
                                        label={"Surname"}
                                        control={control}
                                        rules={{
                                            required: false,
                                            maxLength: {
                                                value: 255,
                                                message: "Max 255 symbols"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1.05} />

                                <Grid item xs={12} sx={{ height: 48 }} />
                                <Grid item xs={0.75}>
                                    <WorkOutlineOutlined sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                                </Grid>
                                <Grid item xs={5.1}>
                                    <FormInputText
                                        name={"organization"}
                                        label={"Name of the organization"}
                                        control={control}
                                        rules={{
                                            required: false,
                                            maxLength: {
                                                value: 255,
                                                message: "Max 255 symbols"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5.1}>
                                    <FormInputText
                                        sx={{ marginLeft: 3 }}
                                        name={"jobTitle"}
                                        label={"Job title"}
                                        control={control}
                                        rules={{
                                            required: false,
                                            maxLength: {
                                                value: 255,
                                                message: "Max 255 symbols"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1.05} />

                                <Grid item xs={12} sx={{ height: 48 }} />
                                {fields.map((field, index) => {
                                    return (
                                        <EmailFields
                                            key={field.id}
                                            index={index}
                                            control={control}
                                            append={append}
                                            remove={remove}
                                        />
                                    );
                                })}

                                <Grid item xs={12} sx={{ height: 24 }} />
                                {phonesFields.map((field, index) => {
                                    return (
                                        <PhoneFields
                                            key={field.id}
                                            index={index}
                                            control={control}
                                            append={appendPhone}
                                            remove={removePhone}
                                        />
                                    );
                                })}
                                <Grid item xs={12}
                                    sx={{ height: 48 }}
                                />
                                <Grid item xs={0.75}>
                                    <HomeOutlined sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                                </Grid>
                                <Grid item xs={5.1}>
                                    <CountryDropdown
                                        name={'country'}
                                        control={control}
                                        label={'Country'}
                                        options={countries}
                                        setValue={setValue}
                                    />
                                </Grid>
                                <Grid item xs={5.1}>
                                    <RegionDropdown
                                        name={'region'}
                                        control={control}
                                        label={'Region'}
                                        options={countries}
                                        sx={{ marginLeft: 3 }}
                                        watch={watch}
                                    />
                                </Grid>
                                <Grid item xs={1.05} />

                                <Grid item xs={12} sx={{ height: 24 }} />
                                <Grid item xs={0.75} />
                                <Grid item xs={5.1}>
                                    <FormInputText
                                        name={"city"}
                                        label={"City"}
                                        control={control}
                                        rules={{
                                            required: false,
                                            maxLength: {
                                                value: 255,
                                                message: "Max 255 symbols"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5.1}>
                                    <FormInputText
                                        name={'street'}
                                        label={'Street address'}
                                        control={control}
                                        rules={{
                                            required: false,
                                            maxLength: {
                                                value: 255,
                                                message: "Max 255 symbols"
                                            }
                                        }}
                                        sx={{ marginLeft: 3 }}
                                    />
                                </Grid>
                                <Grid item xs={1.05} />

                                <Grid item xs={12} sx={{ height: 24 }} />
                                <Grid item xs={0.75} />
                                <Grid item xs={5.1}>
                                    <FormInputText
                                        name={'street2'}
                                        label={'Street address line 2'}
                                        control={control}
                                        rules={{
                                            required: false,
                                            maxLength: {
                                                value: 255,
                                                message: "Max 255 symbols"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5.1}>
                                    <FormInputText
                                        name={'streetPostcode'}
                                        label={'Postal code'}
                                        control={control}
                                        sx={{ marginLeft: 3 }}
                                        rules={{
                                            required: false,
                                            maxLength: {
                                                value: 255,
                                                message: "Max 255 symbols"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1.05} />

                                <Grid item xs={12} sx={{ height: 48 }} />
                                {/* Date of Birth */}
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: 658 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <CakeOutlined sx={{ mr: 2, color: 'rgba(0, 0, 0, 0.54)' }} />
                                        <Grid container spacing={2}>
                                            {/* Day selection field */}
                                            <Grid item xs={4}>
                                                <Controller
                                                    name="day"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        validate: (value) => {
                                                            if (!value) {
                                                                return 'Please enter a valid date';
                                                            }
                                                            const intDay = parseInt(value, 10);
                                                            if (Number.isNaN(intDay)) {
                                                                return 'The day must be a number';
                                                            }
                                                            if (!selectedMonth) return true; // If no month is selected, day validation is not performed.
                                                            const effectiveYear =
                                                                selectedYear && !Number.isNaN(parseInt(selectedYear, 10))
                                                                    ? selectedYear
                                                                    : '2020'; // Use default year if year is not specified
                                                            const daysInMonth = moment(
                                                                `${effectiveYear}-${selectedMonth}`,
                                                                'YYYY-M',
                                                                true
                                                            ).daysInMonth();
                                                            if (intDay < 1 || intDay > daysInMonth) {
                                                                return `The selected month must have between 1 and ${daysInMonth} days`;
                                                            }
                                                            const dateStr = selectedYear
                                                                ? `${selectedYear}-${selectedMonth}-${value}`
                                                                : `2020-${selectedMonth}-${value}`;
                                                            const date = moment(dateStr, 'YYYY-M-D', true);
                                                            if (!date.isValid()) {
                                                                return 'Invalid date format';
                                                            }
                                                            return true;
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            label="Day"
                                                            variant="standard"
                                                            error={Boolean(errors.day)}
                                                            helperText={errors.day ? errors.day.message : ''}
                                                            onChange={(e) => {
                                                                const newValue = e.target.value;
                                                                field.onChange(newValue);
                                                                // Delayed trigger call to update the value
                                                                setTimeout(() => {
                                                                    trigger(['month', 'day']);
                                                                }, 0);
                                                            }}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: 'rgba(0, 0, 0, 0.54)',
                                                                    fontSize: '16px',
                                                                    fontFamily: 'Roboto',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: 400,
                                                                    lineHeight: '24px',
                                                                    letterSpacing: '0.15px',
                                                                },
                                                            }}
                                                            inputProps={{
                                                                inputMode: 'numeric',
                                                                'data-lpignore': 'true',
                                                                autoComplete: 'off',
                                                                sx: {
                                                                    color: 'rgba(0, 0, 0, 0.84)',
                                                                    fontSize: '16px',
                                                                    fontFamily: 'Roboto',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: 400,
                                                                    lineHeight: '24px',
                                                                    letterSpacing: '0.15px',
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            {/* Month selection field */}
                                            <Grid item xs={4} ml={'-10px'} mr={'2px'}>
                                                <Controller
                                                    name="month"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: !!selectedDay }}
                                                    render={({ field }) => (
                                                        <FormControl fullWidth error={Boolean(errors.month)}>
                                                            <InputLabel sx={{
                                                                color: 'rgba(0, 0, 0, 0.54)',
                                                                fontSize: '16px',
                                                                fontFamily: 'Roboto',
                                                                fontStyle: 'normal',
                                                                fontWeight: 400,
                                                                lineHeight: '24px',
                                                                letterSpacing: '0.15px',
                                                            }}>Month</InputLabel>
                                                            <Select
                                                                {...field}
                                                                labelId="month-label"
                                                                label="Month"
                                                                variant="standard"
                                                                onChange={(e) => {
                                                                    const newValue = e.target.value;
                                                                    field.onChange(newValue);
                                                                    // Delayed trigger call to update the value
                                                                    setTimeout(() => {
                                                                        trigger(['month', 'day']);
                                                                    }, 0);
                                                                }}
                                                                sx={{
                                                                    color: 'rgba(0, 0, 0, 0.84)',
                                                                    fontSize: '16px',
                                                                    fontFamily: 'Roboto',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: 400,
                                                                    // lineHeight: '24px',
                                                                    letterSpacing: '0.15px',
                                                                }}
                                                                MenuProps={{
                                                                    PaperProps: {
                                                                        sx: {
                                                                            '& .MuiMenuItem-root': {
                                                                                color: 'rgba(0, 0, 0, 0.87)',
                                                                                fontSize: '16px',
                                                                                fontFamily: 'Roboto',
                                                                                fontStyle: 'normal',
                                                                                fontWeight: 400,
                                                                                lineHeight: '24px',
                                                                                letterSpacing: '0.15px',
                                                                            },
                                                                        },
                                                                    },
                                                                }}
                                                            >
                                                                {months.map((name, index) => (
                                                                    <MenuItem key={index} value={index + 1}>
                                                                        {name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                            {errors.month && (
                                                                <FormHelperText>{errors.month.message}</FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    )}
                                                />
                                            </Grid>
                                            {/* Year selection field */}
                                            <Grid item xs={4}>
                                                <Controller
                                                    name="year"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        validate: (value) => {
                                                            if (!value) {
                                                                return true; // Year is optional
                                                            }
                                                            const intYear = parseInt(value, 10);
                                                            if (Number.isNaN(intYear)) {
                                                                return 'The year must be a number';
                                                            }
                                                            if (intYear < 0) {
                                                                return 'The year cannot be less than 0';
                                                            }
                                                            if (value.length > 4) {
                                                                return 'The year cannot be more than 4 digits.';
                                                            }
                                                            return true;
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            label="Year (optional)"
                                                            variant="standard"
                                                            error={Boolean(errors.year)}
                                                            helperText={errors.year ? errors.year.message : ''}
                                                            onChange={(e) => {
                                                                const newValue = e.target.value;
                                                                field.onChange(newValue);
                                                                setTimeout(() => {
                                                                    trigger('day');
                                                                }, 0);
                                                            }}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: 'rgba(0, 0, 0, 0.54)',
                                                                    fontSize: '16px',
                                                                    fontFamily: 'Roboto',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: 400,
                                                                    lineHeight: '24px',
                                                                    letterSpacing: '0.15px',
                                                                },
                                                            }}
                                                            inputProps={{
                                                                inputMode: 'numeric',
                                                                'data-lpignore': 'true',
                                                                autoComplete: 'off',
                                                                sx: {
                                                                    color: 'rgba(0, 0, 0, 0.84)',
                                                                    fontSize: '16px',
                                                                    fontFamily: 'Roboto',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: 400,
                                                                    lineHeight: '24px',
                                                                    letterSpacing: '0.15px',
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                        {/* <CakeOutlined sx={{ ml: 2, color: 'rgba(0, 0, 0, 0.54)' }} /> */}
                                    </Box>
                                </Box>

                                <Grid item xs={12} sx={{ height: 24 }} />
                                <Grid item xs={0.75}>
                                    <FormatAlignLeftOutlined sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                                </Grid>
                                <Grid item xs={11.25}>
                                    <FormInputText
                                        name={"description"}
                                        label={"Contact short description"}
                                        control={control}
                                        multiline
                                        rules={{
                                            required: false,
                                            maxLength: {
                                                value: 255,
                                                message: "Max 255 symbols"
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                        <Button onClick={handleClose}><Typography variant={'dialogButton'}
                            color={'rgba(0, 0, 0, 0.54)'}>Cancel</Typography></Button>
                        <LoadingButton type="submit" loading={loading} disabled={!hasAnyValue(formValues)}>
                            <Typography variant={'dialogButton'}
                                sx={{
                                    color: loading || !hasAnyValue(formValues) ? 'rgba(0, 0, 0, 0.32)' : 'rgba(59, 125, 237, 1)'
                                }}
                            >
                                Update
                            </Typography>
                        </LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}

function EmailFields({ index, control, append, remove }) {
    return (
        <>
            {index > 0 && (<Grid item xs={12} sx={{ height: 24 }} />)}
            <Grid item xs={0.75}>
                <EmailOutlined sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
            </Grid>
            <Grid item xs={5.1}>
                <FormInputText
                    name={`emails[${index}].email`}
                    label={`Email`}
                    control={control}
                    type={'email'}
                    rules={{
                        required: false,
                        maxLength: {
                            value: 255,
                            message: "Max 255 symbols"
                        }
                    }}
                />
            </Grid>
            <Grid item xs={5.1}>
                <FormInputDropdown
                    name={`emails[${index}].label`}
                    control={control}
                    label={'Label'}
                    options={labelOptions}
                    sx={{ marginLeft: 3 }}
                />
            </Grid>
            {
                index ?
                    <Grid item xs={1.05}
                        sx={{
                            display: 'flex',
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                        }}
                    >
                        <IconButton edge='end' aria-label="close"
                            onClick={() => {
                                remove(index)
                            }}>
                            <CloseOutlined
                                sx={{
                                    width: 24,
                                    flexShrink: 0,
                                    color: 'rgba(59, 125, 237, 1)',
                                }}
                            />
                        </IconButton>
                    </Grid>
                    :
                    <Grid item xs={1.05}
                        sx={{
                            display: 'flex',
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                        }}
                    >
                        <IconButton
                            edge='end'
                            onClick={() => {
                                append({ email: '', label: '' });
                            }}
                        >
                            <AddCircleOutlineOutlined
                                sx={{
                                    width: 24,
                                    flexShrink: 0,
                                    color: 'rgba(59, 125, 237, 1)',
                                }}
                            />
                        </IconButton>
                    </Grid>
            }
        </>
    )
}


function PhoneFields({ index, control, append, remove }) {
    return (
        <>
            {index > 0 && (<Grid item xs={12} sx={{ height: 24 }} />)}
            <Grid item xs={0.75}>
                <PhoneOutlined sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
            </Grid>
            <Grid item xs={5.1}>
                <FormInputText
                    name={`phones.${index}.phone`}
                    label={`Phone`}
                    control={control}
                    rules={{
                        required: false,
                        maxLength: {
                            value: 255,
                            message: "Max 255 symbols"
                        }
                    }}
                />
            </Grid>
            <Grid item xs={5.1}>
                <FormInputDropdown
                    name={`phones.${index}.label`}
                    control={control}
                    label={'Label'}
                    options={labelOptions}
                    sx={{ marginLeft: 3 }}
                />
            </Grid>
            {
                index ?
                    <Grid item xs={1.05}
                        sx={{
                            display: 'flex',
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                        }}
                    >
                        <IconButton edge='end' aria-label="close"
                            onClick={() => {
                                remove(index)
                            }}>
                            <CloseOutlined
                                sx={{
                                    width: 24,
                                    flexShrink: 0,
                                    color: 'rgba(59, 125, 237, 1)',
                                }}
                            />
                        </IconButton>
                    </Grid>
                    :
                    <Grid item xs={1.05}
                        sx={{
                            display: 'flex',
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                        }}
                    >
                        <IconButton
                            edge='end'
                            onClick={() => {
                                append({ phone: '', label: '' });
                            }}
                        >
                            <AddCircleOutlineOutlined
                                sx={{
                                    width: 24,
                                    flexShrink: 0,
                                    color: 'rgba(59, 125, 237, 1)',
                                }}
                            />
                        </IconButton>
                    </Grid>
            }
        </>
    )
}


function DateOfBirth({ control, setValue }) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
                defaultValue={null}
                name={'dateOfBirth'}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <DatePicker
                        // format='MM/DD/YY'
                        label="Date of birth"
                        value={value}
                        onChange={(date) => {
                            setValue('dateOfBirth', date, { shouldValidate: true, shouldDirty: true });
                        }}
                        slots={{
                            openPickerIcon: CalendarTodayOutlined
                        }}
                        slotProps={{
                            textField: {
                                InputProps: {
                                    size: 'medium',
                                    readOnly: true,
                                    sx: {
                                        width: '285.59px',
                                        color: 'rgba(0, 0, 0, 0.54)',
                                        fontSize: 16,
                                        fontFamily: `"Roboto"`,
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        letterSpacing: '0.15px',
                                    },
                                },
                                InputLabelProps: {
                                    sx: {
                                        color: 'rgba(0, 0, 0, 0.54)',
                                        fontSize: 16,
                                        fontFamily: `"Roboto"`,
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        letterSpacing: '0.15px',
                                    },
                                },
                                variant: 'standard'
                            }
                        }}
                    />
                )}
            />
        </LocalizationProvider>
    )
}

const FormInputText = ({ name, control, label, rules, sx, autoFocus = false, type = 'text', multiline = false }) => {
    return (
        <Controller
            defaultValue={''}
            name={name}
            control={control}
            rules={rules}
            render={({
                field: { onChange, value },
                fieldState: { error },
                formState,
            }) => {
                return (
                    <TextField
                        type={type}
                        helperText={error ? error.message : null}
                        error={!!error}
                        onChange={onChange}
                        value={value}
                        autoFocus={autoFocus}
                        label={label}
                        fullWidth
                        variant="standard"
                        sx={sx}
                        multiline={multiline}
                        InputLabelProps={{
                            style: {
                                color: 'rgba(0, 0, 0, 0.54)',
                                fontSize: '16px',
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '24px',
                                letterSpacing: '0.15px',
                            },
                        }}
                        inputProps={{
                            'data-lpignore': 'true',
                            autoComplete: 'off',
                            sx: {
                                color: 'rgba(0, 0, 0, 0.84)',
                                fontSize: '16px',
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '24px',
                                letterSpacing: '0.15px',
                            }
                        }}
                    />
                )
            }
            }
        />
    );
};

const FormInputDropdown = ({ name, control, label, options, sx = null }) => {
    const generateSingleOptions = () => {
        return options.map((option) => {
            return (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            );
        });
    };
    return (
        <FormControl variant="standard" fullWidth sx={sx}>
            <InputLabel sx={{
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '16px',
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '0.15px',
            }}>{label}</InputLabel>
            <Controller
                defaultValue={''}
                render={({ field: { onChange, value } }) => (
                    <Select
                        sx={{
                            color: 'rgba(0, 0, 0, 0.84)',
                            fontSize: '16px',
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '24px',
                            letterSpacing: '0.15px',
                        }}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    '& .MuiMenuItem-root': {
                                        color: 'rgba(0, 0, 0, 0.87)',
                                        fontSize: '16px',
                                        fontFamily: 'Roboto',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        letterSpacing: '0.15px',
                                    },
                                },
                            },
                        }}
                        onChange={onChange}
                        value={value}
                        label={label}
                        defaultValue={''}
                    >
                        {generateSingleOptions()}
                    </Select>
                )}
                control={control}
                name={name}
            />
        </FormControl>
    );
};

const CountryDropdown = ({ name, control, label, options, sx = null, setValue }) => {
    const generateSingleOptions = () => options.map((option) => (
        <MenuItem key={option.country.name} value={option.country.shortCode}>
            {option.country.name}
        </MenuItem>
    ));
    return (
        <FormControl variant="standard" fullWidth sx={sx}>
            <InputLabel sx={{
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '16px',
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '0.15px',
            }}>{label}</InputLabel>
            <Controller
                defaultValue={''}
                render={({ field: { onChange, value } }) => (
                    <Select
                        sx={{
                            color: 'rgba(0, 0, 0, 0.84)',
                            fontSize: '16px',
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '24px',
                            letterSpacing: '0.15px',
                        }}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    '& .MuiMenuItem-root': {
                                        color: 'rgba(0, 0, 0, 0.87)',
                                        fontSize: '16px',
                                        fontFamily: 'Roboto',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        letterSpacing: '0.15px',
                                    },
                                },
                            },
                        }}
                        onChange={(country) => {
                            setValue('country', country.target.value);
                            setValue('region', '', { shouldValidate: true, shouldDirty: true });
                        }}
                        value={value}
                        label={label}
                    >
                        {generateSingleOptions()}
                    </Select>
                )}
                control={control}
                name={name}
            />
        </FormControl>
    );
};

const RegionDropdown = ({ name, control, label, options, sx = null, watch }) => {
    const country = options.find(country => country.country.shortCode === watch('country'));
    const generateSingleOptions = () => country && country.country.regions ? country.country.regions.map((region) => (
        <MenuItem key={region.name} value={region.name}>
            {region.name}
        </MenuItem>
    )) : [];
    return (
        <FormControl variant="standard" fullWidth sx={sx}>
            <InputLabel sx={{
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '16px',
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '0.15px',
            }}>{label}</InputLabel>
            <Controller
                defaultValue={''}
                render={({ field: { onChange, value } }) => {
                    return (
                        <Select
                            sx={{
                                color: 'rgba(0, 0, 0, 0.84)',
                                fontSize: '16px',
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '24px',
                                letterSpacing: '0.15px',
                            }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        '& .MuiMenuItem-root': {
                                            color: 'rgba(0, 0, 0, 0.87)',
                                            fontSize: '16px',
                                            fontFamily: 'Roboto',
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            lineHeight: '24px',
                                            letterSpacing: '0.15px',
                                        },
                                    },
                                },
                            }}
                            onChange={onChange}
                            value={value}
                            label={label}
                        >
                            {generateSingleOptions()}
                        </Select>
                    )
                }}
                control={control}
                name={name}
            />
        </FormControl>
    );
};
