import React, { useEffect } from 'react';
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, Grid, IconButton,
    Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { CloseOutlined } from "@mui/icons-material";

// project import
import store from "../../store/store";
import {
    openWarningShareAllContactsDialog,
    openShareLabelsDialog,
    openMeetingDialog
} from "../../store/reducers/dialogSlice";
import { setGroupsToShare } from "../../store/reducers/groupsSlice";

const MeetingDialog = () => {
    const userInitialized = useSelector((state) => state.user.userInitialized);
    const open = useSelector((state) => state.dialogsOpen.meeting.value);
    const user = useSelector((state) => state.user.user);
    useEffect(() => {
        if (open && userInitialized) {
            const script = document.createElement('script');
            script.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
            script.type = "text/javascript";
            script.async = true;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
        }
        return undefined;
    }, [open, userInitialized]);
    if (!userInitialized) return null;
    const firstName = user.givenName || '';
    const sureName = user.familyName || '';
    const url = `https://meetings-eu1.hubspot.com/meetings/dmasnyi?embed=true&firstName=${firstName}&lastName=${sureName}&email=${user.email}`
    const handleClose = () => {
        store.dispatch(openMeetingDialog(false));
    };
    return (
        <Dialog open={open} onClose={handleClose} fullWidth id='WarningShareAllContactsDialog'
            PaperProps={{
                sx: {
                    width: 930,
                    maxWidth: 930,
                    minWidth: 930,
                    height: 810,
                    backgroundColor: 'rgba(59, 125, 237, 1)',
                }
            }}
        >
            <DialogTitle sx={{ backgroundColor: 'rgba(59, 125, 237, 1)' }}>
                <Grid container sx={{ alignItems: 'center' }}>
                    <Grid item>
                        <Typography
                            variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Book a demo</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: 'rgba(255, 255, 255, 0.54)',
                    '&:hover': {
                        color: 'rgba(255, 255, 255, 0.87)',
                        backgroundColor: 'rgba(0, 0, 0, 0.16)'
                    },
                }}
            >
                <CloseOutlined />
            </IconButton>
            <DialogContent dividers sx={{ border: 0, backgroundColor: 'rgba(255, 255, 255, 1)', overflow: 'hidden' }}>
                <div className="meetings-iframe-container"
                    data-src={url} />
            </DialogContent>
            <DialogActions sx={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                <Button onClick={handleClose}>
                    <Typography variant={'dialogButton'} color={'rgba(59, 125, 237, 1)'}>Close</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MeetingDialog;
