import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Dialog,
    Checkbox,
    DialogTitle,
    Typography, DialogContent, DialogActions, Button,
    Stack, IconButton, FormControlLabel, Grid,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";

// project import
import { openAddContactToLabelsDialog } from "../../store/reducers/dialogSlice";
import { selectGroups, addContactsToGroups } from "../../store/reducers/groupsSlice";
import store from "../../store/store";
import { addContactsToGroup, setRowSelectionModel } from "../../store/reducers/contactsSlice";

export default function AddContactsToLabelsDialog() {
    const dispatch = useDispatch();
    const open = useSelector((state) => state.dialogsOpen.addContactsToLabels.value);
    const rowSelectionModel = useSelector((state) => state.contacts.rowSelectionModel);
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    const addContactsToGroupsStatus = useSelector((state) => state.groups.addContactsToGroupsStatus);
    const loading = addContactsToGroupsStatus === 'loading';
    let groups = useSelector(selectGroups);
    const [selectedGroups, setSelectedGroups] = useState([]);
    if (!open) return null;
    groups = groups.filter(group => group.resource !== currentGroup.resource)
        .sort((a, b) => {
            const aParts = a.name.split(/(\d+)/).filter(Boolean);
            const bParts = b.name.split(/(\d+)/).filter(Boolean);
            const minLen = Math.min(aParts.length, bParts.length);
            for (let i = 0; i < minLen; i += 1) {
                if (Number.isNaN(Number(aParts[i])) === false && Number.isNaN(Number(bParts[i])) === false) {
                    const diff = parseInt(aParts[i], 10) - parseInt(bParts[i], 10);
                    if (diff !== 0) return diff;
                }
                else {
                    const diff = aParts[i].localeCompare(bParts[i]);
                    if (diff !== 0) return diff;
                }
            }
            return aParts.length - bParts.length;
        });
    const handleClose = () => {
        setSelectedGroups([]);
        dispatch(openAddContactToLabelsDialog(false))
    };
    const handleChange = (event) => {
        const resource = event.target.id;
        if (event.target.checked) {
            setSelectedGroups([...selectedGroups, resource]);
        } else {
            setSelectedGroups(selectedGroups.filter(item => item !== resource));
        }
    };
    const handleAddContactsToGroup = async () => {
        const result = await store.dispatch(addContactsToGroups({
            groupsResources: selectedGroups,
            contactsResources: rowSelectionModel
        }));
        for (let i = 0; i < result.payload?.groups?.length; i += 1) {
            store.dispatch(addContactsToGroup({
                ...result,
                payload: { ...result.payload, group: result.payload.groups[i] }
            }));
        }
        store.dispatch(setRowSelectionModel([]));
        handleClose();
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    maxWidth: 444,
                    width: 444,
                    minWidth: 444,
                    backgroundColor: 'rgba(59, 125, 237, 1)',
                }
            }}
        >
            <DialogTitle sx={{ backgroundColor: 'rgba(59, 125, 237, 1)' }}>
                <Grid container sx={{ alignItems: 'center' }}>
                    <Grid item>
                        <Typography
                            variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Add contact</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: 'rgba(255, 255, 255, 0.54)',
                    '&:hover': {
                        color: 'rgba(255, 255, 255, 0.87)',
                        backgroundColor: 'rgba(0, 0, 0, 0.16)'
                    },
                }}
            >
                <CloseOutlined />
            </IconButton>
            <DialogContent dividers sx={{ border: 0, backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                <Stack direction={'column'} spacing={0}>
                    {groups.map((item) => (
                        <Stack key={item.resource} spacing={0}>
                            <FormControlLabel
                                key={item.resource}
                                control={
                                    <Checkbox
                                        id={item.resource}
                                        checked={item.selected}
                                        onChange={handleChange}
                                        color='primary'
                                    />
                                }
                                sx={{
                                    '.MuiFormControlLabel-label': {
                                        color: 'rgba(0, 0, 0, 0.87)',
                                        fontSize: 16,
                                        fontFamily: `"Roboto"`,
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: '150%',
                                        letterSpacing: '0.15px',
                                        width: '300px',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                    }
                                }}
                                label={item.name}
                            />
                        </Stack>
                    ))}
                </Stack>
            </DialogContent>
            <DialogActions sx={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                <Button onClick={handleClose}>
                    <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Cancel</Typography>
                </Button>
                <LoadingButton
                    variant="text"
                    loading={loading}
                    onClick={handleAddContactsToGroup}
                    disabled={selectedGroups.length === 0 || loading}
                >
                    <Typography
                        variant={'dialogButton'}
                        sx={{
                            color: selectedGroups.length === 0 || loading ? 'rgba(0, 0, 0, 0.32)' : 'rgba(59, 125, 237, 1)'
                        }}
                    >
                        Add Contacts
                    </Typography>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

