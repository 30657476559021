import { call, fork, join, select, all, put } from 'redux-saga/effects';

import {
    getUserSaga, getUserSuccess,
    getSubscriptionSaga, getSubscriptionSuccess,
    getDomainInformationSaga, getDomainInformationSuccess,
    setSubscriptionType, setCurrency, setCounter, setPricePerUser, getAmountSaga, getAmountSuccess,
    updateShareableUsers,
} from '../../reducers/userSlice';

import {
    getContactsAndLabelsSaga,
    getContactsAndLabelsSuccess,
    updateStatusFirstLoad,
} from '../../reducers/contactsSlice';

import {
    updateGroups,
    setDomainData,
} from '../../reducers/groupsSlice';

import { usersCountToPlanCount } from "../../../services/usersCountToPlanCount";
import sockets from "../../../api/sockets";

function* getUser() {
    const result = yield call(getUserSaga);
    yield put(getUserSuccess(result));
    return result;
}

function* getSubscription() {
    const result = yield call(getSubscriptionSaga);
    yield put(getSubscriptionSuccess(result));
    return result;
}


function* getContactsAndLabels() {
    const result = yield call(getContactsAndLabelsSaga);
    yield put(getContactsAndLabelsSuccess(result));
    return result;
}

function* getDomainInformation() {
    const result = yield call(getDomainInformationSaga);
    yield put(getDomainInformationSuccess(result));
    return result;
}

function* getAmount(data) {
    const result = yield call(getAmountSaga, data);
    yield put(getAmountSuccess(result));
    return result;
}

export function* loadContactsAndLabels() {
    const contactsAndGroupsTask = yield fork(getContactsAndLabels);
    const groupsAndContacts = yield join(contactsAndGroupsTask);
    const user = yield select(state => state.user);
    const contacts = yield select(state => state.contacts);
    if (user.userInitialized && contacts.contactsInitialized && user.domainInfoInitialized) {
        yield processContactsAndLabels(groupsAndContacts, user.user);

        const shareableDomainUsers = user.domainData.users.filter(
            elem => elem.email && elem.email.toLowerCase() !== user.user.email.toLowerCase()
        )
        const domainUsers = shareableDomainUsers.map(el => {
            if (el.email) {
                return {
                    email: el.email,
                    name: el.name.fullName,
                    photo: el.photo,
                    group: false,
                    domainUser: true
                }
            }
            return null
        })
        yield put(updateShareableUsers(domainUsers));

        const domainsGroups = user.domainData.groups;
        if (domainsGroups && domainsGroups.length) {
            const preparedDomainGroups = domainsGroups.map(el => {
                if (el.email) {
                    return { email: el.email, name: el.name, photo: null, group: true }
                }
                return null
            })
            yield put(updateShareableUsers(preparedDomainGroups));
        }

        const shareableContacts = contacts.contacts.reduce((acc, item) => {
            if (item.email && item.email.toLowerCase() !== user.user.email.toLowerCase()) {
                acc.push({
                    email: item.email,
                    name: item.name,
                    photo: item.photo,
                    group: false,
                });
            }
            return acc;
        }, []);
        yield put(updateShareableUsers(shareableContacts));

    }
}

export function* loadBasicData() {
    const statusFirstLoad = yield select(state => state.contacts.statusFirstLoad);
    if (!statusFirstLoad) {
        yield put(updateStatusFirstLoad({ statusFirstLoad: 'loading' }));
    }
    const tasks = yield all([
        fork(getUser),
        fork(getSubscription),
        fork(getDomainInformation),
    ]);
    const result = yield join(tasks);
    const userResult = result[0];
    const subscriptionResult = result[1];
    const domainInformationResult = result[2];
    const isNeedLogin = userResult?.need_login || domainInformationResult?.need_login || subscriptionResult?.need_login;
    if (!isNeedLogin) {
        yield processSubscriptionData(subscriptionResult);
        yield processDomainInformation(domainInformationResult);
        yield call(loadContactsAndLabels);
    }
    yield put(updateStatusFirstLoad({ statusFirstLoad: 'succeeded' }));
}

function* processSubscriptionData(subscriptionResult) {
    if (!subscriptionResult) return;
    const subscription = subscriptionResult;
    yield put(setSubscriptionType(subscription.status !== 0 ? subscription.plan_id === 2 ? 'monthly' : 'annually' : 'annually'));
    yield put(setCurrency(subscription.status !== 0 ? subscription.plan_id !== 1 ? subscription.subscription.currency : 'USD' : 'USD'));
    const SUBSCRIPTION_OVER = subscription.plan_id !== 1 && subscription.status === 0;
    const TRIAL = subscription.plan_id === 1;
    let counter = (!TRIAL && subscription.status === 1) ? subscription.quantity : subscription.connected_users.length;
    counter = Math.max(counter, 2);
    if (subscription.subscription.version === 2) {
        const result = usersCountToPlanCount(counter);
        counter = result.to;
    }
    yield put(setCounter(counter));
    const amount = yield getAmount({
        period: subscription.status !== 0 ? subscription.plan_id === 2 ? 'MONTHLY' : 'YEAR' : 'YEAR',
        quantity: counter,
        version: SUBSCRIPTION_OVER || TRIAL ? 3 : subscription.subscription.version,
        currency: subscription.subscription.currency,
    });
    yield put(setPricePerUser(counter ? Math.trunc((amount?.price / counter) * 100) / 100 : 0));
}

function* processContactsAndLabels(groupsAndContacts, user) {
    if (!groupsAndContacts) return;
    yield put(updateGroups({ groups: groupsAndContacts.groups, user }));
}

function* processDomainInformation(domainInfoResult) {
    if (!domainInfoResult) return;
    const domainInformation = domainInfoResult;
    yield put(setDomainData(domainInformation));
}