import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { put, call, apply } from 'redux-saga/effects';

import axiosInstance from "../../api/axiosInstance";
import { abortPreviousRequest } from "../../api/abortPreviousRequest";
import { showNotification } from "./notificationSlice";
import getErrorMessage from "../../services/getErrorMessage";
import { delay } from "../../api/redux";
import serverApi from "../../api/serverApi";

const initialState = {
    user: {},
    status: 'idle',
    updateSubscriptionStatus: 'idle',
    changeLicensesToAnnualStatus: 'idle',
    setPhoneNumberStatus: 'idle',
    removeUsersFromSubscriptionStatus: 'idle',
    subscriptionSetAdminStatus: 'idle',
    subscriptionAddUsersStatus: 'idle',
    userInitialized: false,
    subscriptionInitialized: false,
    domainInfoInitialized: false,
    statusFirstLoad: null,
    domainIsFirstTime: false,
    error: null,
    needLogin: null,
    domainInstallAlert: false,
    noLicenseAlert: false,
    subscription: {},
    billingEmail: null,
    amount: {
        price: 0,
        save: 0,
    },
    pricePerUser: 0,
    counter: null,
    subscriptionType: 'annually',
    currency: 'USD',
    syncStatus: null,
    selectedUsers: [],
    licensesToDelete: [],
    domainData: {},
    shareableUsers: {
        users: [],
        domainUsersCount: 0,
    },
    amountChanges: {},
    usersAmount: 0,
    usersToDelete: [],
    usersToRemoveAdminPermissions: [],
    usersToAddAdminPermissions: [],
    usersToAddToSubscription: [],
}

export function* getUserSaga() {
    const endpoint = 'get_user';
    const payload = yield serverApi(endpoint);
    const result = yield apply(payload, payload.json);
    return result;
}

export function* getSubscriptionSaga() {
    const endpoint = 'get_subscription_data';
    const payload = yield serverApi(endpoint).then((response) => response.json());
    return payload;
}

export function* getDomainInformationSaga() {
    const endpoint = 'get_domain_information';
    const payload = yield serverApi(endpoint).then((response) => response.json());
    return payload;
}

export function* getAmountSaga(data) {
    const endpoint = 'get_amount';
    const payload = yield serverApi(endpoint, data, 'POST').then((response) => response.json());
    return payload;
}


// export function* getDataSaga() {
//     let endpoint = 'get_user';
//     let payload = yield serverApi(endpoint).then((response) => response.json());
//     yield put(getUserSuccess(payload));
//     endpoint = 'get_subscription_data';
//     payload = yield serverApi(endpoint).then((response) => response.json());
//     yield put(getSubscriptionSuccess(payload));
// }

// export const GET_USER = 'posts/getUser';
// export const getUsers = createAction(GET_USER);

// export const GET_SUBSCRIPTION = 'posts/getSubscription';
// export const getSubscription = createAction(GET_SUBSCRIPTION);

// export const GET_DATA = 'posts/getData';
// export const getDatas = createAction(GET_DATA);

export const getUser = createAsyncThunk('user/getUser', async (_, { rejectWithValue, requestId }) => {
    try {
        // console.log('getUser requestId', requestId, Date.now())
        const data = {};
        // const response = await axiosInstance(data, 'get_user', 'get', abortPreviousRequest(requestId, 'user/getUser'));
        const response = await axiosInstance(data, 'get_user', 'get');
        return response.data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
},
    {
        getPendingMeta: ({ arg }) => {
            return { ignorePreviousRequest: true };
        }
    }
)

export const getSubscriptionData = createAsyncThunk('groups/getSubscriptionData', async (payload, { rejectWithValue, requestId }) => {
    try {
        // console.log('getSubscriptionData requestId', requestId, Date.now())
        const data = {};
        // const response = await axiosInstance(data, 'get_subscription_data', 'get', abortPreviousRequest(requestId, 'groups/getSubscriptionData'));
        const response = await axiosInstance(data, 'get_subscription_data', 'get');
        return response.data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
},
    {
        getPendingMeta: ({ arg }) => {
            return { ignorePreviousRequest: true };
        }
    }
)

export const logout = createAsyncThunk('user/logout', async (_, { rejectWithValue }) => {
    try {
        const data = {};
        const response = await axiosInstance(data, 'logout', 'get');
        return response.data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
})

export const subscriptionChangeLicensesCount = createAsyncThunk('user/subscriptionChangeLicensesCount', async (payload, { rejectWithValue }) => {
    try {
        const data = { subscriptionId: payload.subscriptionId, newCount: payload.newCount };
        const response = await axiosInstance(data, 'subscription_change_licenses_count');
        return response.data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
})

export const calculateAmountChanges = createAsyncThunk('user/calculateAmountChanges', async (payload, { rejectWithValue }) => {
    try {
        const data = { quantity: payload.quantity, period: payload.period };
        const response = await axiosInstance(data, 'calculate_amount_changes');
        return response.data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
})

export const subscriptionChangeLicensesToAnnual = createAsyncThunk('user/subscriptionChangeLicensesToAnnual', async (payload, { rejectWithValue }) => {
    try {
        const data = { subscriptionId: payload.subscriptionId };
        const response = await axiosInstance(data, 'subscription_change_licenses_to_annual');
        return response.data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
})

export const getAmount = createAsyncThunk('user/getAmount', async (payload, { rejectWithValue }) => {
    try {
        const data = JSON.stringify(payload);
        const response = await axiosInstance(data, 'get_amount');
        return response.data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
},
    {
        getPendingMeta: ({ arg }) => {
            return { ignorePreviousRequest: true };
        }
    }
)

export const changeSubscriptionOwner = createAsyncThunk('user/changeSubscriptionOwner', async (payload, { rejectWithValue }) => {
    try {
        const data = JSON.stringify(payload);
        const response = await axiosInstance(data, 'change_subscription_owner');
        return response.data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
})

export const syncUserGroups = createAsyncThunk('user/syncUserGroups', async (_, { rejectWithValue }) => {
    try {
        const data = null;
        const response = await axiosInstance(data, 'sync_user_groups', 'get');
        return response.data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
})

export const removeUsersFromSubscription = createAsyncThunk('user/removeUsersFromSubscription', async (payload, { rejectWithValue }) => {
    try {
        const data = { usersIds: payload };
        const response = await axiosInstance(data, 'remove_users_from_subscription');
        return response.data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
})

export const getDomainInformation = createAsyncThunk('user/getDomainInformation', async (payload, { rejectWithValue }) => {
    try {
        const data = {};
        const response = await axiosInstance(data, 'get_domain_information', 'get');
        return response.data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
},
    {
        getPendingMeta: ({ arg }) => {
            return { ignorePreviousRequest: true };
        }
    }
)

export const exterminateDeletedSubscriptionUsers = createAsyncThunk('user/exterminateDeletedSubscriptionUsers', async (payload, { rejectWithValue, dispatch }) => {
    try {
        const data = { emails: payload.emails };
        const response = await axiosInstance(data, 'exterminate_deleted_subscription_users');
        const hasNoRights = response.data.some(item => item.status === "NO_RIGHTS");
        const allExterminated = response.data.every(item => item.status === "EXTERMINATED");
        let type = 'error';
        let message = '';
        if (hasNoRights) {
            message = 'You do not have the necessary permissions to delete users.';
        } else if (allExterminated) {
            message = `User${payload.emails.length > 1 ? 's' : ''} successfully deleted`;
            type = 'success';
        } else {
            message = 'Something went wrong';
        }
        dispatch(
            showNotification({
                type,
                message,
            })
        );

        return response.data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
})

export const subscriptionAddUsers = createAsyncThunk('user/subscriptionAddUsers', async (payload, {
    rejectWithValue,
    dispatch
}) => {
    try {
        const data = { emails: payload };
        const response = await axiosInstance(data, 'subscription_add_users');
        const getRejectInfoArray = (data) => {
            return data.rejected_emails.map(email => ({
                email,
                reason: data.reject_reasons[email]
            }));
        };
        const rejectInfoArray = getRejectInfoArray(response.data);
        if (rejectInfoArray.length) {
            const message = getErrorMessage(rejectInfoArray);
            dispatch(
                showNotification({
                    type: 'error',
                    message,
                })
            );
        } else {
            dispatch(
                showNotification({
                    type: 'success',
                    message: `License${payload.length > 1 ? 's' : ''} assigned successfully`,
                })
            );
        }

        return response.data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
})

export const getData = createAsyncThunk('user/getData', async (payload, { rejectWithValue }) => {
    try {
        const data = {};
        const response = await axiosInstance(data, 'get_data', 'get');
        return response.data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
})

export const subscriptionAddRestrictions = createAsyncThunk('user/subscriptionAddRestrictions', async (payload, { rejectWithValue }) => {
    try {
        const data = { emails: payload };
        const response = await axiosInstance(data, 'subscription_add_restrictions');
        return response.data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
})

export const subscriptionRemoveRestrictions = createAsyncThunk('user/subscriptionRemoveRestrictions', async (payload, { rejectWithValue }) => {
    try {
        const data = { emails: payload };
        const response = await axiosInstance(data, 'subscription_remove_restrictions');
        return response.data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
})

export const setPhoneNumber = createAsyncThunk('user/setPhoneNumber', async (payload, { rejectWithValue }) => {
    try {
        const data = { phone: payload };
        const response = await axiosInstance(data, 'set_phone_number');
        return response.data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
})


export const subscriptionSetAdmin = createAsyncThunk('user/subscriptionSetAdmin', async (payload, { rejectWithValue, dispatch }) => {
    try {
        const data = payload;
        const response = await axiosInstance(data, 'subscription_set_admin');
        if (response.data.skip_list.length) {
            const message = data.setAdmin ? "You can't assign admin access to some users." : "You can't remove admin access from some users.";
            dispatch(
                showNotification({
                    type: 'error',
                    message,
                })
            );
        } else {
            const message = data.setAdmin ? `Admin access assigned successfully` : `Admin access removed successfully`;
            dispatch(
                showNotification({
                    type: 'success',
                    message,
                })
            );
        }
        return response.data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
})

const userSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        getUserSuccess: (state, action) => {
            state.status = 'succeeded';
            state.needLogin = action.payload.need_login || false;
            // state.statusFirstLoad = 'succeeded';
            if (!state.needLogin) {
                state.user = action.payload.me;
                // state.user.domain_admin = 1;
                // state.user.domain_user = 1;
                // state.user.domain_wide_install = 0;
                state.domainInstallAlert = Boolean(state.user.domain_user && !state.user.domain_wide_install);
                state.domainIsFirstTime = action.payload.domain_is_first_time || false;
                state.userInitialized = true;
            }
        },
        getSubscriptionSuccess: (state, action) => {
            state.needLogin = action.payload.need_login || false;
            state.subscription = action.payload;
            if (!state.needLogin) {
                const subscriptionOwner = state.subscription.licensed_users.find(user => user.user_id === state.subscription.subscription.owner_id);
                state.billingEmail = subscriptionOwner ? subscriptionOwner.email : '';
                state.subscriptionInitialized = true;
                // state.subscription.restricted_users = [];
                // state.subscription.restricted_users = [{email: 'test.deleted.user@gmail.com', user_id: 12345}];
                // state.subscription.deleted_users = [];
                // state.subscription.status = 0;
                // state.subscription.plan_id = 1;
                // state.subscription.remaining_days = 1;
                // state.subscription.subscription.version = 1;
            }
        },
        getDomainInformationSuccess: (state, action) => {
            state.needLogin = action.payload.need_login || false;
            state.domainData = action.payload;
            if (!state.needLogin) {
                state.shareableUsers.domainUsersCount = state.domainData?.users?.length || 0;
                state.noLicenseAlert = state.user?.email !== state.domainData?.domain_subscription?.owner && state.domainData?.domain_subscription?.subscription_status === 'ACTIVE' && state.subscription?.plan_id < 2;
                state.domainInfoInitialized = true;
            }
        },
        getAmountSuccess: (state, action) => {
            state.status = 'succeeded';
            state.needLogin = action.payload.need_login || false;
            if (!state.needLogin) {
                state.amount = {
                    price: action.payload.price,
                    save: action.payload.save,
                };
            }
        },
        setDomainInstallAlert: (state, action) => {
            state.domainInstallAlert = action.payload;
        },
        setBillingEmail: (state, action) => {
            state.billingEmail = action.payload;
        },
        setSyncStatus: (state, action) => {
            state.syncStatus = action.payload;
        },
        setLicensesToDelete: (state, action) => {
            state.licensesToDelete = action.payload;
        },
        setSelectedUsers: (state, action) => {
            state.selectedUsers = action.payload;
        },
        updateShareableUsers: (state, action) => {
            state.shareableUsers.users = shareableUsers(state.shareableUsers.users, action.payload);
        },
        updateSubscriptionInfo: (state, action) => {
            state.subscription = action.payload.subscription;
            const subscriptionOwner = state.subscription.licensed_users.find(user => user.user_id === state.subscription.subscription.owner_id);
            state.billingEmail = subscriptionOwner ? subscriptionOwner.email : '';
            state.subscriptionInitialized = true;
            // state.subscription.subscription.subscription_cancel_at = 'Thu, 09 Feb 2023 08:23:14 GMT'
            // state.subscription.subscription.subscription_next_billing_at = 'Fri, 09 Feb 2024 00:00:00 GMT'
            // state.subscription.status = 1;
            // state.subscription.subscription.subscription_cancel_at = null;
            // state.subscription.plan_id = 1;
            // state.subscription.subscription.owner_id = 13772;
            // state.subscription.subscription.version = 2;
            // state.subscription.licensed_users = state.subscription.licensed_users.map(item => {
            //     item.domains_groups = ['Test', 'Test 1'];
            //     return item;
            // });
            // state.subscription.restricted_users = [{email: 'test.deleted.user@gmail.com', user_id: 12345}];
            // state.subscription.deleted_users = [{email:"test.deleted.user@gmail.com", user_id: 12345}];
            // state.subscription.deleted_users = [{email:"antosha@antosha.net", user_id: 12345}];
        },
        updateUser: (state, action) => {
            state.user = action.payload.user;
            // state.sync = action.payload.sync;
            // state.user.domain_admin = 1;
            // state.user.domain_user = 1;
            // state.user.domain_wide_install = 0;
            state.domainInstallAlert = Boolean(state.user.domain_user && !state.user.domain_wide_install);
            state.userInitialized = true;
        },
        updateDomainInfo: (state, action) => {
            state.domainData = action.payload.domainData;
            if (state.domainData.users && state.domainData.users.length) {
                state.shareableUsers.domainUsers = state.domainData.users.reduce((acc, item) => {
                    return item.email && item.email.toLowerCase() !== state.user.email.toLowerCase() ? [...acc, {
                        email: item.email,
                        name: item.name.fullName,
                        photo: item.photo,
                        group: false,
                        domainUser: true
                    }] : acc;
                }, []);
                if (state.domainData.groups && state.domainData.groups.length) {
                    state.shareableUsers.domainGroups = state.domainData.groups.reduce((acc, item) => {
                        return item.email ? [...acc, {
                            email: item.email,
                            name: item.name,
                            photo: null,
                            group: true
                        }] : acc;
                    }, []);
                }
                state.shareableUsers.domainUsersCount = state.domainData.users.length;
            }
            state.domainInfoInitialized = true;
        },
        setPricePerUser: (state, action) => {
            state.pricePerUser = action.payload;
        },
        setSubscriptionType: (state, action) => {
            state.subscriptionType = action.payload;
        },
        setCurrency: (state, action) => {
            state.currency = action.payload;
        },
        setCounter: (state, action) => {
            state.counter = action.payload;
        },
        updateDomainIsFirstTime: (state, action) => {
            state.domainIsFirstTime = action.payload;
        },
        setUsersAmount: (state, action) => {
            state.usersAmount = action.payload;
        },
        setUsersToDelete: (state, action) => {
            state.usersToDelete = action.payload;
        },
        setUsersToRemoveAdminPermissions: (state, action) => {
            state.usersToRemoveAdminPermissions = action.payload;
        },
        setUsersToAddAdminPermissions: (state, action) => {
            state.usersToAddAdminPermissions = action.payload;
        },
        setUsersToAddToSubscription: (state, action) => {
            state.usersToAddToSubscription = action.payload;
        },
        updateLoginStatus: (state, action) => {
            state.needLogin = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getUser.pending, (state) => {
                state.status = 'loading';
                state.statusFirstLoad = 'loading';
                state.error = null;
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.needLogin = action.payload.need_login;
                state.statusFirstLoad = 'succeeded';
                if (!action.payload.need_login) {
                    state.user = action.payload.me;
                    // state.user.domain_admin = 1;
                    // state.user.domain_user = 1;
                    // state.user.domain_wide_install = 0;
                    state.domainInstallAlert = Boolean(state.user.domain_user && !state.user.domain_wide_install);
                    state.domainIsFirstTime = action.payload.domain_is_first_time || false;
                    state.userInitialized = true;
                }
            })
            .addCase(getUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.statusFirstLoad = 'failed';
            })

            .addCase(getSubscriptionData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getSubscriptionData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // delete action.payload.deleted_users
                state.subscription = action.payload;
                if (!action.payload.need_login) {
                    const subscriptionOwner = state.subscription.licensed_users.find(user => user.user_id === state.subscription.subscription.owner_id);
                    state.billingEmail = subscriptionOwner ? subscriptionOwner.email : '';
                    state.subscriptionInitialized = true;
                    // state.subscription.restricted_users = [];
                    // state.subscription.restricted_users = [{email: 'test.deleted.user@gmail.com', user_id: 12345}];
                    // state.subscription.deleted_users = [];
                    // state.subscription.status = 0;
                    // state.subscription.plan_id = 1;
                    // state.subscription.remaining_days = 1;
                    // state.subscription.subscription.version = 1;
                }
            })
            .addCase(getSubscriptionData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(subscriptionChangeLicensesCount.pending, (state) => {
                state.updateSubscriptionStatus = 'loading';
            })
            .addCase(subscriptionChangeLicensesCount.fulfilled, (state, action) => {
                state.updateSubscriptionStatus = 'succeeded';
            })
            .addCase(subscriptionChangeLicensesCount.rejected, (state, action) => {
                state.updateSubscriptionStatus = 'failed';
                state.error = action.error.message;
            })

            .addCase(getAmount.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAmount.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.needLogin = action.payload.need_login;
                if (!action.payload.need_login) {
                    state.amount = {
                        price: action.payload.price,
                        save: action.payload.save,
                    };
                }
            })
            .addCase(getAmount.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(changeSubscriptionOwner.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(changeSubscriptionOwner.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(changeSubscriptionOwner.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(syncUserGroups.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(syncUserGroups.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(syncUserGroups.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(removeUsersFromSubscription.pending, (state) => {
                state.removeUsersFromSubscriptionStatus = 'loading';
            })
            .addCase(removeUsersFromSubscription.fulfilled, (state, action) => {
                state.removeUsersFromSubscriptionStatus = 'succeeded';
            })
            .addCase(removeUsersFromSubscription.rejected, (state, action) => {
                state.removeUsersFromSubscriptionStatus = 'failed';
                state.error = action.error.message;
            })

            .addCase(getDomainInformation.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getDomainInformation.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // action.payload.domain_subscription.subscription_status = 'NO_ACTIVE'
                state.domainData = action.payload;
                state.shareableUsers.domainUsersCount = state.domainData?.users?.length || 0;
                state.noLicenseAlert = state.user?.email !== state.domainData?.domain_subscription?.owner && state.domainData?.domain_subscription?.subscription_status === 'ACTIVE' && state.subscription?.plan_id < 2;
                // if (state.domainData.users && state.domainData.users.length) {
                // state.shareableUsers.domainUsers = state.domainData.users.reduce((acc, item) => {
                //     return item.email && item.email.toLowerCase() !== state.user.email.toLowerCase() ? [...acc, {
                //         email: item.email,
                //         name: item.name.fullName,
                //         photo: item.photo,
                //         group: false,
                //         domainUser: true
                //     }] : acc;
                // }, []);
                // if (state.domainData.groups && state.domainData.groups.length) {
                //     state.shareableUsers.domainGroups = state.domainData.groups.reduce((acc, item) => {
                //         return item.email ? [...acc, {email: item.email, name: item.name, photo: null, group: true}] : acc;
                //     }, []);
                // }
                // state.shareableUsers.domainUsersCount = state.domainData.users.length;
                // }
                state.domainInfoInitialized = true;
            })
            .addCase(getDomainInformation.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(subscriptionAddUsers.pending, (state) => {
                state.subscriptionAddUsersStatus = 'loading';
            })
            .addCase(subscriptionAddUsers.fulfilled, (state, action) => {
                state.subscriptionAddUsersStatus = 'succeeded';
            })
            .addCase(subscriptionAddUsers.rejected, (state, action) => {
                state.error = action.error.message;
                state.subscriptionAddUsersStatus = 'failed';
            })

            .addCase(calculateAmountChanges.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(calculateAmountChanges.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.amountChanges = action.payload;
            })
            .addCase(calculateAmountChanges.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(subscriptionChangeLicensesToAnnual.pending, (state) => {
                state.changeLicensesToAnnualStatus = 'loading';
            })
            .addCase(subscriptionChangeLicensesToAnnual.fulfilled, (state, action) => {
                state.changeLicensesToAnnualStatus = 'succeeded';
                state.amountChanges = action.payload;
            })
            .addCase(subscriptionChangeLicensesToAnnual.rejected, (state, action) => {
                state.changeLicensesToAnnualStatus = 'failed';
                state.error = action.error.message;
            })

            .addCase(getData.pending, (state) => {
                state.status = 'loading';
                state.error = null;
                if (!state.statusFirstLoad) {
                    state.statusFirstLoad = 'loading';
                }
            })
            .addCase(getData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.needLogin = action.payload.need_login;
            })
            .addCase(getData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.statusFirstLoad = 'failed';
            })

            .addCase(subscriptionAddRestrictions.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(subscriptionAddRestrictions.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(subscriptionAddRestrictions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(subscriptionRemoveRestrictions.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(subscriptionRemoveRestrictions.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(subscriptionRemoveRestrictions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(setPhoneNumber.pending, (state) => {
                state.setPhoneNumberStatus = 'loading';
                state.error = null;
            })
            .addCase(setPhoneNumber.fulfilled, (state, action) => {
                state.setPhoneNumberStatus = 'succeeded';
            })
            .addCase(setPhoneNumber.rejected, (state, action) => {
                state.setPhoneNumberStatus = 'failed';
                state.error = action.error.message;
            })

            .addCase(subscriptionSetAdmin.pending, (state) => {
                state.subscriptionSetAdminStatus = 'loading';
                state.error = null;
            })
            .addCase(subscriptionSetAdmin.fulfilled, (state, action) => {
                state.subscriptionSetAdminStatus = 'succeeded';
            })
            .addCase(subscriptionSetAdmin.rejected, (state, action) => {
                state.subscriptionSetAdminStatus = 'failed';
                state.error = action.error.message;
            })

            .addCase(exterminateDeletedSubscriptionUsers.pending, (state) => {
                state.exterminateDeletedSubscriptionUsersStatus = 'loading';
                state.error = null;
            })
            .addCase(exterminateDeletedSubscriptionUsers.fulfilled, (state, action) => {
                state.exterminateDeletedSubscriptionUsersStatus = 'succeeded';
            })
            .addCase(exterminateDeletedSubscriptionUsers.rejected, (state, action) => {
                state.exterminateDeletedSubscriptionUsersStatus = 'failed';
                state.error = action.error.message;
            })

    },
})

export const selectUsers = (state) => state.contacts.users;

const shareableUsers = (users, payload) => {
    const newUsers = users.concat(payload);
    const unique = [];
    const resultUsers = [];
    /* eslint-disable-next-line */
    for (const user of newUsers) {
        if (!unique.includes(user.email)) {
            unique.push(user.email)
            resultUsers.push(user)
        }
    }
    return resultUsers;
}

export const {
    setDomainInstallAlert,
    setBillingEmail,
    setSyncStatus,
    setLicensesToDelete,
    setSelectedUsers,
    updateShareableUsers,
    updateUser,
    setPricePerUser,
    setSubscriptionType,
    setCurrency,
    setCounter,
    updateDomainIsFirstTime,
    setUsersAmount,
    setUsersToDelete,
    setUsersToRemoveAdminPermissions,
    setUsersToAddAdminPermissions,
    setUsersToAddToSubscription,
    getUserSuccess,
    getSubscriptionSuccess,
    getDomainInformationSuccess,
    getAmountSuccess,
    updateLoginStatus,
} = userSlice.actions;

export default userSlice.reducer
